<template>
  <q-card
    class="flex column q-py-md justify-between items-start question-card"
    :class="[getColorBgBadge('Conhecimento')]"
  >
    <header class="flex row items-center justify-between q-mb-sm">
      <div class="avatar-question q-mr-sm">
        <BadgeType typeBadge="Conhecimento" iconBadge="book" />
        <!-- <q-img src="../../assets/icons/training-icon.svg" spinner-color="white" /> -->
      </div>

      <div class="title-notification">
        <h6>{{ quiz.title }}</h6>
        <small>{{ dateFormatted }}</small>
      </div>
      <main v-show="quizExpired" class="q-pa-md q-mx-md content-question">
        <h6>{{ messageQuiz }}</h6>
      </main>
      <main v-show="validateNoteQuiz" class="q-pa-md q-mx-md content-question">
        <h6 v-if="visibilityResult">
          {{ quiz.show_score ? "Resultado: " + noteQuiz : "quiz concluído" }}
        </h6>
        <h6 v-else>Resultado: -</h6>
      </main>
      <q-icon
        :name="visibilityResult ? 'visibility' : 'visibility_off'"
        @click="teste()"
        style="cursor: pointer"
        size="1.2rem"
        class="q-mr-md"
      />
      <!--      <q-btn-->
      <!--        class="q-mr-md"-->
      <!--        round-->
      <!--        icon="star"-->
      <!--        text-color="green"-->
      <!--        size="sm"-->
      <!--      />-->
    </header>

    <div v-show="validateQuestion">
      <main class="q-pa-md content-question">
        <h6>{{ quiz.title }}</h6>
        <p>{{ questionCard.description_question }}</p>
        <div class="flex justify-between q-px-md">
          <div
            class="row flex col-6 card-option items-center q-pa-md q-my-sm"
            :class="[
              verifyAwnser(option.questionOptionId) ? 'selectedButon' : '',
            ]"
            @click="
              questionCard.option_type_id == 2
                ? selectAwnser(option.questionOptionId)
                : selectOneAwnser(option.questionOptionId)
            "
            v-for="option in questionCard.options"
            :key="option.value"
          >
            <label>{{ option.letter }}</label>
            <p>
              {{ option.description_option }}
            </p>
          </div>
        </div>
      </main>
      <footer class="row flex full-width q-mt-sm">
        <RoundedBtn
          titleButton="Responder"
          icon="coins"
          :subtitle="questionCard.weight"
          :onClickBtn="answerQuestion"
        />
      </footer>
      <q-dialog v-model="cardRating">
        <q-card class="my-card">
          <q-card-section>
            <q-rating
              v-model="starsWiki"
              @click="sendRatingWiki()"
              v-close-popup
              :max="5"
              size="64px"
            />
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </q-card>
</template>

<script>
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import { ref, onMounted } from "vue";
import http from "@/http-common";
import { date } from "quasar";
import BadgeType from "@/components/BadgeType.vue";
import ColorsClass from "@/utils/styles/colors/getColors.js";

export default {
  name: "CardQuestion",
  emits: ["concluded"],
  components: {
    RoundedBtn,
    BadgeType,
  },
  props: {
    quizId: Number,
    trainingId: Number,
    courseId: Number,
    quiz: Object,
  },
  setup(props, {emit}) {
    const Colors = new ColorsClass();

    let dateFormatted = date.formatDate(
      props.quiz.created_at,
      "DD/MM/YYYY H:mm"
    );
    let visibilityResult = ref(false);
    let selectedAwnser = ref([]);
    let cardRating = ref(false);
    let answerIdInPage = ref(null);
    let loading = ref(false);
    let quizExpired = ref(false);
    let messageQuiz = ref("");
    let validateNoteQuiz = ref(false);
    let validateQuestion = ref(false);
    let noteQuiz = ref(null);
    let questionCard = ref([])

   async function changeStatusToConcluded() {
      emit("concluded");
    }

    function selectAwnser(value) {
      if (selectedAwnser.value.includes(value)) {
        const index = selectedAwnser.value.indexOf(value);
        selectedAwnser.value.splice(index, 1);
      } else {
        selectedAwnser.value.push(value);
      }
    }

    function selectOneAwnser(value) {
      if (selectedAwnser.value.length >= 0) {
        selectedAwnser.value = [];
      }
      selectedAwnser.value.push(value);
    }
    function verifyAwnser(questionOptionId) {
      return selectedAwnser.value.includes(questionOptionId);
    }

    onMounted(() => {
      getQuestion();
    });


    async function getQuestion() {
      loading.value = true;

      let courseId = props.courseId;
      let trainingId = props.trainingId;
      let quizId = props.quizId;

      if (props.quiz.concluded) {
        await http
          .get(
            `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/score`
          )
          .then((response) => {
            loading.value = false;
            validateResponse(response);
          })
          .catch(async (error) => {
            loading.value = false;
            validateResponse(response);
          });
      } else {
        await http
          .post(
            `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/start`
          )
          .then((response) => {
            loading.value = false;

            validateResponse(response);
          })
          .catch((error) => {
            loading.value = false;
            validateCatchResponse(error.response.data);
          });
      }
    }

    async function answerQuestion() {
      loading.value = true;

      let courseId = props.courseId;
      let trainingId = props.trainingId;
      let quizId = props.quizId;
      let answerId = answerIdInPage.value;
      let dataAnswer = {
        answers: selectedAwnser.value,
      };

      //dataAnswer.answers.push(selectedAwnser.value);

      await http
        .post(
          `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/answer/${answerId}`,
          dataAnswer
        )
        .then((response) => {
          loading.value = false;
          validateResponse(response);
        })
        .catch((error) => {
          loading.value = false;
          validateCatchResponse(error);
        });
    }

    async function validateResponse(response) {
      //resposta do quiz salva
      if (response.status == 200) {
        validateQuestion.value = true;
        questionCard.value = response.data;
        answerIdInPage.value = response.data.answer_id;
        //resposta do quiz
      } else if (response.status == 201) {
        validateQuestion.value = false;
        validateNoteQuiz.value = true;
        noteQuiz.value = response.data;
        if (response.data == "Evaluation finalizado!") {
          let courseId = props.courseId;
          let trainingId = props.trainingId;
          let quizId = props.quizId;

          await http
            .get(
              `v1/course/${courseId}/training/${trainingId}/quiz/${quizId}/score`
            )
            .then((response) => {
              loading.value = false;
              validateResponse(response);
              changeStatusToConcluded()
            })
            .catch(async (error) => {
              loading.value = false;
              validateResponse(response);
            });
        }
        //error
      } else {
        validateQuestion.value = false;
        quizExpired.value = true;
        messageQuiz.value = response.data;
      }
    }

    function validateCatchResponse(error) {
      validateQuestion.value = false;
      quizExpired.value = true;
      messageQuiz.value = error;
    }

    function teste() {
      visibilityResult.value = !visibilityResult.value;
    }

    function getColorBgBadge(name) {
      return Colors.getColorBgBadge(name);
    }

    return {
      selectedAwnser,
      loading,
      quizExpired,
      messageQuiz,
      cardRating,
      dateFormatted,
      questionCard,
      validateQuestion,
      validateNoteQuiz,
      noteQuiz,
      visibilityResult,
      teste,
      selectAwnser,
      answerQuestion,
      verifyAwnser,
      selectOneAwnser,

      getColorBgBadge,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  width: 100%;
  /* height: 100%;
  max-height: 162px; */
  margin-bottom: 1rem;
  padding: 1% 0%;
  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);
  box-shadow: rgb(0 0 0 / 10%) 3px 6px 15px;
}

header {
  width: 100%;
  position: relative;
  padding: 0 2%;

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    // background-color: #a1cb72;
    position: absolute;
    left: 0%;
    border-radius: 0px 2px 2px 0px;
  }

  .avatar-question {
    width: 45px;
    height: 45px;
    // margin: 0 3%;
    // overflow: hidden;
    // border-radius: 45px;
  }

  .title-notification {
    flex: 1;
    h6 {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 0.87rem;
      line-height: 17px;
      color: #000000;
    }
    span {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 17px;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 0.62rem;
      line-height: 12px;
      color: #0000004d;
    }
  }
}

.content-question {
  background-color: #00000008;
  border-radius: 20px;
  cursor: pointer;

  h6 {
    margin-bottom: 1%;
    font-style: normal;
    font-weight: 600;
    font-size: 0.81rem;
    line-height: 1rem;
  }

  p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 16px;
    color: #00000073;
  }

  .selectedButon {
    border: 1px solid var(--q-primary, #fe4e64);
  }

  .card-option {
    max-width: 45%;
    width: 45%;
    background-color: #fff;
    border-radius: 20px;

    label {
      flex: 1;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 1.68rem;
      line-height: 33px;
    }
    p {
      flex: 2;
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #00000073;
    }
  }
}

.item-bg-pink {
  header::before {
    background-color: var(--q-primary, #fe4e64);
  }
}
.item-bg-yellow {
  header::before {
    background-color: #d49a41;
  }
}
.item-bg-purple {
  header::before {
    background-color: #a55ab3;
  }
}
.item-bg-blue {
  header::before {
    background-color: #3f8ab3;
  }
}
.item-bg-green {
  header::before {
    background-color: #77aa3d;
  }
}
.item-bg-depp-green {
  header::before {
    background-color: #439380;
  }
}
</style>
